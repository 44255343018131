import React from 'react'
import { Link } from 'gatsby'

const ButtonLandL = ({
	link = null,
	target = '',
	children,
	className,
	modifier = 'normal',
	onClick,
	forceOnScreen = false
}) => {

	const handleOnKeyDown = e => {
		if (e.key === 'Enter') {
			onClick();
		}
	}

	const buttonContent = () => {
		if (link)
			return (
				<Link
					to={link}
					target={target}
					className={`Button-new Button--${modifier}`}
				>
					{children}
				</Link>
			)
		else {
			return (
				<div
					className={`Button-new Button--${modifier}`}
					onClick={onClick}
					onKeyDown={handleOnKeyDown}
					role="button"
					tabIndex={0}
				>
					{children}
				</div>
			)
		}
	}

	return (
		<div
			className={`Button__Wrapper ${className} ${forceOnScreen ? 'forceOnScreen' : ''}`}
			data-is-on-screen
		>
			{buttonContent()}
		</div>
	)
}

export default ButtonLandL
