import React, { useState, useEffect } from 'react'
import Select from 'react-select'

const InputSelectLandLPage = ({
	options,
	defaultValue = null,
	onChange,
	placeholder,
	className = '',
	value,
	optionsFontSize = '1.1vw',
	isMulti
}) => {

	const [customSelectStyles, setCustomSelectStyles] = useState({});

	useEffect(() => {
		const bp = 375;
		const windowWidth = typeof window !== 'undefined' ? window.innerWidth : 1440;
		const isMobile = windowWidth > bp ? false : true;

		const newCustomSelectStyles = {
			clearIndicator: (provided) => ({
				...provided,
				color: '#FFF',
				fontSize: '1.1vh'
			}),
			container: (provided) => ({
				...provided,
				width: '100%'
			}),
			control: (provided) => ({
				...provided,
				backgroundColor: 'none',
				border: 'none',
				borderRadius: 0,
				height: isMobile ? '5vw' : '2.9166vw',
				cursor: 'pointer',
				width: isMobile ? '80vw' : '100%',
				boxShadow: 'none',
				whiteSpace: 'nowrap'
			}),
			dropdownIndicator: () => ({
				display: 'none'
			}),
			indicatorSeparator: () => ({
				display: 'none'
			}),
			placeholder: () => ({
				fontSize: isMobile ? '3.6vw' : '1.1vw',
				color: '#959595'
			}),
			valueContainer: (provided) => ({
				...provided,
				backgroundColor: '#1D1D1D',
				padding: 0,
				flexWrap: 'nowrap'
			}),
			menu: (provided) => ({
				...provided,
				backgroundColor: 'rgba(26, 26, 26, 1)',
				width: '100%'
			}),
			singleValueContainer: (provided) => ({
				...provided,
				flexWrap: 'nowrap'
			}),
			singleValue: () => ({
				fontSize: isMobile ? '3.6vw' : optionsFontSize,
				width: '100%'
			}),
			multiValue: (provided) => ({
				...provided,
				backgroundColor: '#1D1D1D'
			}),
			multiValueLabel: (provided) => ({
				...provided,
				backgroundColor: '#1D1D1D',
				color: '#FFF',
				fontSize: 7
			}),
			multiValueRemove: (provided) => ({
				...provided,
				backgroundColor: '#1D1D1D',
				color: '#FFF'
			}),
			option: (provided, state) => ({
				backgroundColor: state.isFocused ? '#1D1D1D' : 'none',
				color: state.isFocused ? '#FFF' : 'none',
				height: isMobile ? '8vw' : '2.777vw',
				fontSize: isMobile ? '3.6vw' : optionsFontSize,
				display: 'flex',
				alignItems: 'center',
				padding: isMobile ? '0 2vw' : '0 0.3472vw',
				cursor: 'pointer',
				whiteSpace: 'nowrap'
			})
		}

		setCustomSelectStyles(newCustomSelectStyles)
	}, [])

	return (
		<div className={`InputSelect ${className}`}>
			<Select
				options={options}
				onChange={onChange}
				placeholder={placeholder}
				defaultValue={defaultValue}
				value={value}
				styles={customSelectStyles}
				isClearable={false}
				isSearchable={false}
				isMulti={isMulti}
			/>
			<svg className="InputSelect__Arrow_NEW" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0)" stroke="#fff" strokeWidth="1.5"><path d="M7.58 0v12.579M1.274 6.797l6.305 6.284 6.306-6.284"/></g><defs><clipPath id="clip0"><path fill="#fff" transform="rotate(90 7.177 7.177)" d="M0 0h14v13.548H0z"/></clipPath></defs></svg>
			<div className="Input__SelectTextCropperNew"></div>
		</div>
	)
}

export default InputSelectLandLPage