import React, { useState, useContext, useEffect } from 'react'
import SEO from '../components/SEO'
import logoImg from '../images/Shildan_L&L_Logo_NOBG.png'
import "../styles/pages/lunchAndLearn.scss"
import InputSelectLandLPage from '../components/InputSelectL&LPage'
import Button from '../components/ButtonL&L'
import HeaderLandL from '../components/HeaderL&L'
import { detectElementsOnScreen, killObservers } from '../helpers/detectElementsOnScreen'
import { ContextLoader } from '../context';


const LunchAndLearnLandingPage = ({ data }) => {

  const [isLoading] = useContext(ContextLoader);
	useEffect(() => {
		if (isLoading === true) return
		const [observers, elements] = detectElementsOnScreen()
		return () => {
			killObservers(observers, elements)
		}
	}, [isLoading])

  const API_KEY="eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjM1MjE5MDc2MSwiYWFpIjoxMSwidWlkIjo1OTM4MzQzOCwiaWFkIjoiMjAyNC0wNC0yNVQxNzo0OTozOC4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MjI4NjEzOTQsInJnbiI6InVzZTEifQ.AMugfZQnbFNnnfzsCBS7NsGB4vd7VJjKZIJW2rwf3oc";
  const BOARD_ID="7857388502";
  let isReady = false;

  const [dropdownSelectedOption, setDropdownSelectedOption] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [address, setAddress] = useState("");
  const [whichCourse, setWhichCourse] = useState("");
  const [howToHear, setHowToHear] = useState("");
  const [freeFormOne, setFreeFormOne] = useState("");
  const [freeFormTwo, setFreeFormTwo] = useState("");
  const [freeFormThree, setFreeFormThree] = useState("");
  const [isHidden1, setIsHidden1] = useState(true);
  const [isHidden2, setIsHidden2] = useState(true);
  const [isHidden3, setIsHidden3] = useState(true);
  const [isValidatedEmail, setIsValidatedEmail] = useState(true);
  const [isValidatedCompany, setIsValidatedCompany] = useState(true);
  const [isValidatedName, setIsValidatedName] = useState(true);
  const [isValidatedAddress, setIsValidatedAddress] = useState(true);
  const [isValidatedDrop, setIsValidatedDrop] = useState(true);
  const [isValidatedCourse, setIsValidatedCourse] = useState(true);
  const [isValidatedHear, setIsValidatedHear] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [countdown, setCountdown] = useState(4);


  const pageTitle = "Schedule Your Lunch & Learn | Shildan Group";
	const metaDescription = "Want to schedule a Lunch & Learn or Wine & Cheese with our AIA-accredited course. Do so here.";
	const metaKeywords = "Architectural, Shildan, Course credit";

  const options = [
    { value: 'Architect', label: 'Architect' },
    { value: 'Building Owner', label: 'Building Owner' },
    { value: 'Contractor', label: 'Contractor' },
    { value: 'Distributor', label: 'Distributor' },
    { value: 'Facade Engineer', label: 'Facade Engineer' },
    { value: 'Consultant', label: 'Consultant' },
    { value: 'Installer', label: 'Installer' },
    { value: 'Home Owner', label: 'Home Owner' },
    { value: 'Other', label: 'Other' }
  ];

  const navItems = {
    data: {
      top_navigation: [
        { 
          link_text: { raw: [{ type: "paragraph", text: "Design & Innovation" }] },
          link: { type: "design", url: "/design_and_innovation" }
        },
        { 
          link_text: { raw: [{ type: "paragraph", text: "Our Products" }] },
          link: { type: "products", url: "/our_products" }
        },
        { 
          link_text: { raw: [{ type: "paragraph", text: "Featured Projects" }] },
          link: { type: "projects", url: "/projects" }
        },
        { 
          link_text: { raw: [{ type: "paragraph", text: "Get In Touch" }] },
          link: { type: "contact", url: "/get_in_touch" }
        },
        { 
          link_text: { raw: [{ type: "paragraph", text: "Blog" }] },
          link: { type: "blog", url: "/blog" }
        },
        { 
          link_text: { raw: [{ type: "paragraph", text: "Download Center" }] },
          link: { type: "downloads", url: "/download_center" }
        }
      ]
    }
  };

  const footer = {
    data: {
      connect_with_us_links: [
        { type: "facebook", url: "https://facebook.com/company" },
        { type: "twitter", url: "https://twitter.com/company" },
        { type: "linkedin", url: "https://linkedin.com/company" },
        { type: "instagram", url: "https://instagram.com/company" }
      ],
      corporate_headquarters_address: {
        street: "123 Main Street",
        city: "Anytown",
        state: "CA",
        zip: "12345",
        country: "USA"
      }
    }
  };
  

  const handleSelectChange = (event, type) => {
    setDropdownSelectedOption(event);
    if(event.value === "Other") {
      setIsHidden3(false);
    } else {
      setIsHidden3(true);
    }
    }

    const handleButtonClick = async (e) => {
      e.preventDefault();

       isReady = validateForm();

      if(isReady) {

              const query = `
              mutation {
                create_item (
                  board_id: "${BOARD_ID}",
                  item_name: "${name}",
                  column_values: "${JSON.stringify({
                    email__1: { email: email, text: name },
                    short_text8__1: address,
                    short_text__1: company,
                    status__1: dropdownSelectedOption.value,
                    short_text0__1: freeFormOne.value,
                    status2__1: whichCourse,
                    short_text1__1: freeFormTwo,
                    status1__1: howToHear,
                    short_text2__1: freeFormThree,
                  }).replace(/"/g, '\\"')}"
                ) {
                  id
                }
              }
            `;
  
          try {
            const response = await fetch("https://api.monday.com/v2", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: API_KEY,
              },
              body: JSON.stringify({ query }),
            });
        
            const result = await response.json();
            console.log("Data sent to Monday.com:", result);
            if (result.errors) {
              console.error("Error from Monday.com:", result.errors);
            } else {
              // Show success message
              setShowMessage(true);
    
              // Start countdown
              let timeLeft = 4;
              const timer = setInterval(() => {
                timeLeft -= 1;
                setCountdown(timeLeft);
    
                if (timeLeft <= 0) {
                  clearInterval(timer);
                  // Redirect to home page
                  window.location.href = "/";
                }
              }, 1000);
            }
          } catch (error) {
            console.error("Failed to send data:", error);
          }
      }

    }

    const validateForm = () => {

        let allValid = true;
      
        // Define the validation conditions
        const validations = [
          { value: email, setter: setIsValidatedEmail, validate: validateEmail },
          { value: dropdownSelectedOption, setter: setIsValidatedDrop },
          { value: whichCourse, setter: setIsValidatedCourse },
          { value: howToHear, setter: setIsValidatedHear },
          { value: name, setter: setIsValidatedName },
          { value: company, setter: setIsValidatedCompany },
          { value: address, setter: setIsValidatedAddress },
        ];
      
        // Check all validations
        validations.forEach(({ value, setter, validate }) => {
          let isValid = value !== "";
          if (validate) {
            isValid = validate(value); // Call validateEmail if provided
          }
          setter(isValid); // Update the validation state
          if (!isValid) {
            allValid = false; // Mark as invalid if any condition fails
          }
        });
      
        return allValid;

    };

    const validateEmail = (email) => {
      // Simple regex to check the format of the email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

  return (
  <div className="landlOuterContainer">
    <HeaderLandL currentPage="" navigation={navItems} />
    <div className="landlContainer">
      <SEO title={pageTitle} description={metaDescription} keywords={metaKeywords} />
			{/* <HeaderLandL currentPage="" navigation={navItems} /> */}
    <header className="header">
        <img src={logoImg} alt="Shildan Group" className="logo" />
      </header>
      <div className="belowImage">
        <h1 className="image_h1">Schedule Your Lunch & Learn/Wine & Cheese</h1>
        <h2 className="image_h2">AIA Accredited Course</h2>
      </div>


      {/* Main Content */}
      <main className="main-content">
        <section className="course-info">
          <p className="aboutTitle">About this course:</p>
          <p className="aboutMain">
          This continuing education course addresses the principles of the rainscreen system and focuses on the back-ventilated and pressure-equalized version. The emphasis will be to apply the principles of this system to its use with terracotta panels. Attendees of this course will be provided with an AIA Certificate of Attendance.          </p>
          <p className="aboutDuring">During this course you will learn:</p>
          <ul className="listStyling">
            <li>The Fundamentals of Rainscreen</li>
            <li>Benefits of Rainscreen Facades</li>
            <li>
              Why the Terracotta Rainscreen System is time-tested and
              maintenance-free
            </li>
            <li>
              Benefits of LEED, Sustainability, and how healthy wall construction
              is achievable with rainscreen facades
            </li>
          </ul>
          <p className="courseCredit">Course Credit:</p>
          <p className="credit">
           1 LU | HSW
          </p>
          <p className="belowCredit">
            Please complete the form below to request a Lunch & Learn or Wine &
            Cheese with our AIA-accredited course, or to schedule a Terracotta
            Capabilities presentation today.
          </p>
        </section>

        {/* Registration Form */}
        <form className="registration-form">
          <h3 className="registerText">Register now to reserve your spot in the next available group</h3>
          <div className="form-group-1">
              <input 
              className={isValidatedEmail ? "" : "email-input"}
              type="email" 
              placeholder="Enter your email here *" 
              onChange={(e) => setEmail(e.target.value)}
              />
              <input 
              className={isValidatedName ? "" : "email-input"}
              type="text" 
              placeholder="First and Last Name *" 
              onChange={(e) => setName(e.target.value)}
              />
          </div>
          <div className="form-group-1">
              <input
              className={isValidatedCompany ? "" : "email-input"} 
              type="text" 
              placeholder="Company *" 
              onChange={(e) => setCompany(e.target.value)}
              />
              <input
              className={isValidatedAddress ? "" : "email-input"}
              type="text" 
              placeholder="Address *" 
              onChange={(e) => setAddress(e.target.value)}
              />
          </div>
          <div className="form-group-select">
            <label className="iAmLabel">
              I am *
              <InputSelectLandLPage
                className={isValidatedDrop ? "input-select--product-type iAmSelect" : "input-select--product-type iAmSelectFalse"}
                placeholder={dropdownSelectedOption ? dropdownSelectedOption.value : 'Choose an Option'}
                options={options}
                isMulti={false}
                value={dropdownSelectedOption}
                onChange={e =>handleSelectChange(e, 'Selection')}
              />
            </label>
            <div className="otherTextHolder-inputSelect">
              <input 
              className={isHidden3 ? "isHidden" : "isVisibleTop"} 
              type="text" 
              placeholder="Please specify" 
              onChange={(e) => setFreeFormOne(e.target)}
              />
            </div>
          </div>
          <div className="bottom-form-container">
          <div className="form-radio-inner-1">
          <ul className={isValidatedCourse ? "radioP" : "radioPFalse"}>Which course are you interested in? *
          <li>
            <label className="radioLabel-1">
              <input 
              className="radioFont" 
              type="radio" 
              name="course" 
              value="The Rainscreen Principles (1 LU | HSW Credit)" 
              onChange={(e)=> {
                setIsHidden1(true);
                setWhichCourse(e.target.value);
              }} 
              />
              The Rainscreen Principles (1 LU | HSW Credit)
              </label> 
          </li>
          <li>
            <label className="radioLabel-2">
              <input 
              className="radioFont" 
              type="radio" 
              name="course" 
              value="Capabilities in Terracotta" 
              onChange={(e)=> {
                setIsHidden1(true);
                setWhichCourse(e.target.value);
              }} 
              />
              Capabilities in Terracotta
            </label>
          </li>
          <li>
            <label className="radioLabel-3">
              <input 
              className="radioFont" 
              type="radio" 
              name="course" 
              value="Other"
              onChange={(e)=> {
                setIsHidden1(false);
                setWhichCourse(e.target.value);
              }}               />
              Other
            </label>
          </li>
          </ul>
          <div className="otherTextHolder-1">
          <input 
          className={isHidden1 ? "isHidden" : "isVisible"} 
          type="text" 
          placeholder="Please specify" 
          onChange={(e) => setFreeFormTwo(e.target.value)}
          />
          </div>
          </div>
          <div className="form-radio-inner-2">
          <ul className={isValidatedHear ? "radioP" : "radioPFalse"}>How did you hear about this course? *
          <li>
          <label className="radioLabel-11">
              <input 
              className="radioFont" 
              type="radio" 
              name="source" 
              value="Social Media" 
              onChange={(e)=>{
                setIsHidden2(true);
                setHowToHear(e.target.value);
              }}
               />              
              Social Media
              </label>
            </li>
            <li>
            <label className="radioLabel-22">
              <input  
              className="radioFont"
              type="radio" 
              name="source" 
              value="Word of Mouth" 
              onChange={(e)=>{
                setIsHidden2(true);
                setHowToHear(e.target.value);
              }}
               /> 
              Word of Mouth
              </label>
            </li>
            <li>
            <label className="radioLabel-22">
              <input  
              className="radioFont"
              type="radio" 
              name="source" 
              value="Website" 
              onChange={(e)=>{
                setIsHidden2(true);
                setHowToHear(e.target.value);
              }}
               /> 
              Website
              </label>
            </li>
            <li>
            <label className="radioLabel-33">
              <input  
              className="radioFont"
              type="radio" 
              name="source" 
              value="Email" 
              onChange={(e)=>{
                setIsHidden2(true);
                setHowToHear(e.target.value);
              }}
               /> 
              Email
            </label>
            </li>
            <li>
            <label className="radioLabel-44">
              <input  
              className="radioFont"
              type="radio" 
              name="source" 
              value="Other"
              onChange={(e)=>{
                setIsHidden2(false);
                setHowToHear(e.target.value);
              }} 
              />
              Other
            </label>
            </li>
            </ul>
            <div className="otherTextHolder-2">
                <input 
                className={isHidden2 ? "isHidden" : "isVisible"} 
                type="text" 
                placeholder="Please specify" 
                onChange={(e) => setFreeFormThree(e.target.value)}
                />
            </div>
          </div>
          </div>
          <Button modifier="normal"
						className="button_css"
            forceOnScreen={true}
            onClick={handleButtonClick}>
          Signup Now
          </Button>
        </form>
        {showMessage && (
      <div className="popup-message">
        <p>
          Thank You! Your submission was completed successfully. You will be redirected back
          to the home page in {countdown} seconds.
        </p>
      </div>
        )}
      </main>
    </div>
  </div>
  )
}

export default LunchAndLearnLandingPage
